const menu = [
  {
    name: 'organizations',
    route: '/organization/list',
    icon: 'fas fa-university'
  },
  {
    name: 'orgChart',
    route: '/organization-chart',
    icon: 'fas fa-chart-line'
  },
  {
    name: 'employees',
    route: '/employee',
    icon: 'fa fa-users'
  },
  {
    name: 'students',
    route: '/student',
    icon: 'fa fa-child',
    children: [
      {
        name: 'groups',
        route: '/student/student-group/list',
        icon: 'fas fa-users'
      }
    ]
  },
  {
    name: 'food',
    route: '/food',
    icon: 'fa fa-utensils',
    children: [
      {
        name: 'mealtime',
        route: '/food/mealtime',
        icon: 'fa fa-clock'
      },
      // {
      //   name: 'journalMenu',
      //   route: '/food/journal-menu/list',
      //   icon: 'fa fa-ballot'
      // },
      {
        name: 'products',
        route: '/food/product/list',
        icon: 'fa fa-lemon'
      },
      {
        name: 'technoCards',
        route: '/food/techno-card/list',
        icon: 'fa fa-object-group'
      },
      {
        name: 'menuPlan',
        route: '/food/menu-plan/list',
        icon: 'fa fa-map'
      },
      {
        name: 'journalMenu',
        route: '/food/journal-menu/list',
        icon: 'fa fa-calendar-alt'
      },
      {
        name: 'warehouses',
        route: '/food/warehouse/list',
        icon: 'fa fa-shopping-basket'
      },
      {
        name: 'physicalStandards',
        route: '/food/physical-standards',
        icon: 'fa fa-running'
      }
    ]
  },
  {
    name: 'education',
    route: '/education-system',
    icon: 'fas fa-chalkboard-teacher',
    children: [
      {
        name: 'educationSystems',
        route: '/education-system/list',
        icon: 'fas fa-chalkboard-teacher'
      },
      {
        name: 'levels',
        route: '/education-system/level/list',
        icon: 'fas fa-layer-group'
      },
      {
        name: 'cycles',
        route: '/education-system/cycle/list',
        icon: 'fas fa-layer-group'
      },
      {
        name: 'programmes',
        route: '/education-system/programme/list',
        icon: 'fas fa-laptop-code'
      },
      {
        name: 'grades',
        route: '/education-system/grade/list',
        icon: 'fas fa-laptop-code'
      },
      {
        name: 'subjects',
        route: '/education-system/subject/list',
        icon: 'fas fa-book-reader'
      },
      {
        name: 'textbooks',
        route: '/education-system/textbook/list',
        icon: 'fas fa-book'
      },
      {
        name: 'academicPeriods',
        route: '/education-system/academic-period/list',
        icon: 'fas fa-book-reader'
      }
    ]
  },
  {
    name: 'users',
    route: '/user/list',
    icon: 'fa fa-users',
    children: [
      {
        name: 'systemRoles',
        route: '/user/system-role/list',
        icon: 'fas fa-user-cog'
      }
    ]
  },
  {
    name: 'settings',
    route: '/settings',
    icon: 'fa fa-cog',
    children: [
      {
        name: 'positions',
        route: '/settings/position/list',
        icon: 'fa fa-user-tie'
      },
      {
        name: 'department',
        route: '/settings/department/list',
        icon: 'fa fa-user-tie'
      },
      {
        name: 'charts',
        route: '/settings/chart/list',
        icon: 'fas fa-chart-line'
      },
      {
        name: 'reference',
        route: '/settings/reference/list',
        icon: 'fa fa-chart-bar'
      },
      {
        name: 'dashboard',
        route: '/settings/dashboard/list',
        icon: 'fa fa-chart-bar'
      }
    ]
  }
]
export default menu
