<template>
    <nav class="navbar-default">
        <ul class="depth-1" id="metismenu">
            <li class="nav-header">
                <img :alt="getUserFullName" class="profile-img-sm rounded-circle mb-2"
                     src="../../assets/img/7.jpg">
                <div class="nav-header-text">
                    <div class="font-weight-bold">{{ getUserFullName }}</div>
                </div>
                <div class="clear-both"></div>
            </li>
            <li>
                <router-link to="/competitions/list">
                    <i class="fas fa-file-signature"></i>
                    <span class="nav-label">Competitions</span>
                </router-link>
                <div class="clear-both"></div>
            </li>
            <li>
                <router-link to="/user/list">
                    <i class="fas fa-users"></i>
                    <span class="nav-label">Users</span>
                </router-link>
                <div class="clear-both"></div>
            </li>
            <li>
                <router-link to="/answer-messages/list">
                    <i class="fas fa-users"></i>
                    <span class="nav-label">Answer Massages</span>
                </router-link>
                <div class="clear-both"></div>
            </li>
            <li>
                <router-link to="/winners/generate">
                    <i class="fas fa-users"></i>
                    <span class="nav-label">Winners</span>
                </router-link>
                <div class="clear-both"></div>
            </li>
        </ul>
    </nav>
</template>

<script>
    import {mapGetters} from 'vuex'

    // require('../../../static/metisMenu.min')

    export default {
        name: 'SideBar',
        data() {
            return {
                items: [],
                expanded: 'aria-expanded'
            }
        },
        methods: {
            hasChild(item) {
                return item.children && item.children.length > 0
            },
        },
        computed: {
            ...mapGetters({
                getUserFullName: 'getUserFullName'
            })
        },
        async mounted() {
        }
    }
</script>

<style scoped>

</style>
