const mainBarMenu = [
  {
    name: 'home',
    route: '/',
    icon: 'fa fa-home mr-2',
    children: [
      // {name: 'organizations', route: '/organization/list'},
      {name: 'employees', route: '/employee/list'},
      {name: 'orgChart', route: '/organization-chart'},
      {name: 'students', route: '/student/list'},
      {name: 'groups', route: '/student/student-group/list'},
      {name: 'committees', route: '/committee/list'}
      // {name: 'food', route: '/food'},
      // {name: 'education', route: '/education-system/list'},
      // {name: 'settings', route: '/settings'}
    ]
  },
  {
    name: 'food',
    route: '/food',
    icon: 'fa fa-utensils mr-2',
    children: [
      {name: 'mealtime', route: '/food/mealtime'},
      {name: 'menuPlan', route: '/food/menu-plan/list'},
      {name: 'journalMenu', route: '/food/journal-menu/list'}

      // {name: 'products', route: '/food/product/list'},
      // {name: 'technoCards', route: '/food/techno-card/list'},
      // {name: 'physicalStandards', route: '/food/physical-standards'},
      // {name: 'warehouses', route: '/food/warehouse/list'}
    ]
  },
  {
    name: 'health',
    icon: 'fas fa-briefcase-medical mr-2'
  },
  {
    name: 'education',
    route: '/education-system',
    icon: 'fas fa-chalkboard-teacher mr-2',
    children: [
      // {name: 'educationSystems', route: '/education-system/list'},
      // {name: 'levels', route: '/education-system/level/list'},
      // {name: 'cycles', route: '/education-system/cycle/list'},
      {name: 'programmes', route: '/education-system/programme-top/list'},
      // {name: 'grades', route: '/education-system/grade/list'},
      {name: 'subjects', route: '/education-system/subject-top/list'},
      {name: 'textbooks', route: '/education-system/textbook-top/list'},
      // {name: 'academicPeriods', route: '/education-system/academic-period/list'},
      {name: 'shifts', route: '/education-system/shift/list'}
    ]
  },
  {
    name: 'attendance',
    icon: 'fas fa-user-check mr-2'
  },
  {
    name: 'development',
    icon: 'fas fa-chart-line mr-2'
  },
  {
    name: 'stock',
    icon: 'fas fa-warehouse mr-2'
  },
  {
    name: 'salary',
    icon: 'fas fa-hand-holding-usd mr-2'
  },
  {
    name: 'documents',
    icon: 'fas fa-file-alt mr-2'
  },
  {
    name: 'reports',
    icon: 'fas fa-chart-bar mr-2'
  }
]
export default mainBarMenu
