<template>
    <div class="wrapper">
        <SideBar/>
        <div class="main">
            <MainBar/>
            <div class="main-body m-4 p-3" :class="{'bg-white': hasWhite}">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import SideBar from '@/components/layout/SideBar'
    import MainBar from '@/components/layout/MainBar'

    export default {
  name: 'DefaultLayout',
  computed: {
    ...mapGetters({
      hasWhite: 'hasWhiteBackground'
    })
  },
  components: {
    SideBar,
    MainBar
  }
}
</script>

<style scoped>

</style>
