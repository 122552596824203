<template>
    <header class="px-2">
        <div class="d-flex align-items-center">
            <div class="btn btn-primary mr-4" id="sidebar-toggle">
                <i class="fa fa-bars"></i>
            </div>
            <nav class="navbar navbar-expand-lg navbar-light navbar-main bg-white">
                <button class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </nav>
        </div>
        <div id="user-settings-panel" class="d-flex align-items-center">
            <a href="#" @click="logout">
                <i class="fa fa-sign-out-alt mr-2"></i>
                <span>Log out</span>
            </a>
        </div>
    </header>
</template>

<script>
    import $ from 'jquery'
    import menu from './menu-items'
    import topMenu from './top-menu-items'

    export default {
  name: 'MainBar',
  data () {
    return {
      items: menu,
      selectedLang: 'ru',
      navBarItems: topMenu,
      moreItems: [],
      moreBtn: false,
      labelledby: 'aria-labelledby'
    }
  },
  mounted () {
    const sidebarBtn = $('#sidebar-toggle')
    sidebarBtn.click(() => {
      const body = $('body')
      if (body.hasClass('mini-nav')) {
        body.removeClass('mini-nav')

        const sidebarMenu = $('#metismenu')
        sidebarMenu.css('opacity', 0).animate({
          'opacity': 1
        }, 400, () => sidebarMenu.css('opacity', 1))
      } else {
        body.addClass('mini-nav')
        $('a[aria-expanded="true"]')
          .attr('aria-expanded', 'false')
          .siblings('.mm-show')
          .removeClass('mm-show')
      }
    })
    let navItems = []; let navWidth = 0
    $('.navbar-nav .nav-item').each((idx, item) => {
      const _item = $(item)
      navItems.push(_item)
      navWidth += _item.outerWidth()
    })
    let windowWidth = $('body').outerWidth()
    windowWidth -= $('.navbar-default').outerWidth()
    windowWidth -= $('#user-settings-panel').outerWidth()
    windowWidth -= sidebarBtn.outerWidth()
    if ((windowWidth - navWidth - 20) < 0) {
      let itemsWidth = 0; let idx = 0
      for (let i = 0; i < navItems.length; i++) {
        itemsWidth += navItems[i].outerWidth()
        if (itemsWidth >= (windowWidth - 100)) {
          idx = i - 1
          break
        }
      }
      this.moreItems = this.navBarItems.slice(idx)
      this.navBarItems = this.navBarItems.slice(0, idx)
      this.moreBtn = true
    }
  },
  computed: {},
  methods: {
    logout () {
      this.$store.dispatch('logout', {root: true})
        .then(status => {
          if (status === 200) {
            this.$router.push('/login')
          }
        })
    },
  }
}
</script>

<style scoped>

</style>
